<template>
  <div class="m-2 p-2 sm:m-4 sm:p-4">
    <p class="mb-4 text-sm">
      Kirjaudu sisään, niin voit hallita ilmoituksiasi.
    </p>
    <form id="loginForm" class="flex flex-col" @submit.prevent="login()">
      <label for="email">Sähköposti:</label>
      <input
        id="email"
        v-model="email"
        type="text"
        name="email"
        class="text-field"
      />
      <label for="password" class="mt-6">Salasana:</label>
      <input
        id="password"
        v-model="password"
        type="password"
        name="password"
        class="text-field"
      />

      <input
        type="submit"
        value="Kirjaudu"
        class="py-2 px-8 mt-10 border-1 border-gray-200 rounded-md text-xl tracking-wide text-white bg-green-600"
      />
      <div class="flex flex-row justify-around mt-8">
        <router-link
          :to="{ name: 'home' }"
          class="flex flex-row justify-around"
        >
          <span class="text-sm">Unohtuiko salasana?</span>
        </router-link>
      </div>
    </form>
  </div>
  <base-alert v-model:display="alert" v-model:alerts="errors" />
</template>

<script>
import BaseAlert from '../../components/BaseAlert';
export default {
  components: {
    BaseAlert,
  },
  data: () => {
    return {
      email: '',
      password: '',
      alert: false,
      errors: {},
    };
  },
  methods: {
    async login() {
      var form = document.getElementById('loginForm');
      try {
        const response = await this.$store.dispatch(
          'login',
          new FormData(form)
        );
        this.$router.push({ name: 'home' });
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        this.errors = error.response.data.errors;
        this.alert = true;
      }
    },
  },
};
</script>
