<template>
  <div
    v-if="display"
    class="flex top-0 left-0 fixed w-full h-full bg-gray-700 bg-opacity-50"
  >
    <div
      class="flex flex-col w-4/5 h-3/4 m-auto p-2 bg-red-100 border rounded-md border-red-400 text-red-700"
    >
      <div v-for="(alert, index) in alerts" :key="index" class="p-2">
        {{ alert }}
      </div>
      <input
        type="button"
        value="Sulje"
        class="mt-8 py-2 px-8 border border-gray-600 rounded-md text-xl tracking-wide text-white bg-green-600"
        @click="hide()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    alerts: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:display', 'update:alerts'],
  data: () => {
    return {};
  },
  watch: {
    display() {
      if (this.display !== false) {
        setTimeout(() => {
          this.hide();
        }, 4000);
      }
    },
  },

  methods: {
    hide() {
      this.$emit('update:display', false);
      this.$emit('update:alerts', []);
    },
  },
};
</script>
